body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

body > #root > .MuiCircularProgress-root-1 {
  margin: 2rem auto;
  display: grid;
}
